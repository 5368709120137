import React from "react";
import { data } from "../../constants";
import './News.css'
import { images } from '../../constants';


const News = () => {
    return (
        <div className="news-container app__bg flex__center section__padding" id="news">
            <h1 className="headtext__cormorant">Aktuelles</h1>
            <div className="news-items">
                <h3 className="p__cormorant news-item-date">27.11.24</h3>
                <p className="p__opensans">Hallo ihr Lieben, hier noch eine Info zu unseren Öffnungszeiten im Dezember. Vom 01.12. bis zum 08.12. sind wir in den Betriebsferien. Ab Montag den 09.12. sind wir täglich ab 17 Uhr für euch da. Dies gilt nicht für die Feiertage am 24.12, 25.12., 26.12. und 31.12. Wir freuen uns auf euch!</p>
                <h3 className="p__cormorant news-item-date">05.11.24</h3>
                <img src={images.openingHoursFall} style={{ width: '100%', padding: 10 }}></img>
                {/* <h3 className="p__cormorant news-item-date">08.10.24</h3>
                <img src={images.skat} style={{ width: '100%', padding: 10 }}></img>
                <h3 className="p__cormorant news-item-date">27.07.24</h3>
                <p className="p__opensans">Liebe Gäste, bitte beachten Sie, dass wir am Montag, den 29.07.24 geschlossen haben.</p>
                <h3 className="p__cormorant news-item-date">21.03.24</h3>
                <p className="p__opensans">Liebe Gäste, bitte beachten Sie, dass wir unsere Öffnungszeiten angepasst haben.</p>
                <h3 className="p__cormorant news-item-date">28.02.24</h3>
                <p className="p__opensans">Am 16. März feiern wir unsere Neueröffnung und laden Sie dazu herzlich ein. Wir freuen uns auf Sie!</p> */}
            </div>
        </div>
    )
}

export default News;